
// borders
$border-break: 4px;
$border-emphasis: 2px;
$border-width: 1px;

// border-radius
$br-sm: .5rem;
$br-md: .75rem;
$br-lg: 1rem;
$br-xl: 2rem;

$breakpoints: (
    "mini": 550px,
    "small": 800px,
    "medium": 1000px,
    "large": 1400px
)



