@use '../abstracts' as *;

.item-card {
    font-family: map-get($font-stack, 'sans-serif');
    font-size: 1rem;
    &__container {
        position: relative;
        width: 14em;
        margin: 1em;
        border-radius: $br-lg;
        //border: $border-emphasis solid $color-border;
        padding: .75em;
        display: flex;
        flex-direction: column;
        //background: $color-primary;
        background: #eee;
        list-style: none;
    }

    &__section {
        //position: relative;
        display: flex;
        //padding: .25em .75rem;
        //border-radius: $br-sm;
        //border: $border-width solid $color-border;
        //background: $color-white;
        color: $color-border;
        &--left {
            margin-right: auto;
        }
        &--right {
            margin-left: auto;
        }
        &--name {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        padding-top: .5rem;

        &__label {

            font-size: 1.25rem;
            line-height: 1.5rem;
            color: $color-border;
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }

    &__tag {
        padding: .25rem .75rem;
        margin: .5rem .25rem;
        background-color: $color-add;
        border-radius: $br-md;
        border: $border-width solid $color-border;
    }

    &__buttons {
        width: 100%;
        margin-top: 1em;
        padding: .25em 0;
        /* May need to change display options down the road */
        display: flex;
        justify-content: space-around;
    }

    &__delete {
        background-color: $color-delete;
        border-radius: 50%;
        border: $border-emphasis solid $color-border;
        width: 1.2rem;
        height: 1.2rem;
        display:flex;
        justify-content: center;
        align-items: center;
    }
}

.-overlap {
    position: absolute;
    right: .75rem;
}

.-left {
    margin-left: 0;
    margin-right: auto;
}

.-right {
    margin-right: 0;
    margin-left: auto;
}