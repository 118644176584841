@use '../abstracts/' as *;

.expiration {
    border-top: 1px dotted $color-border;
    border-bottom: 1px dotted $color-border;
    display: flex;
    flex-direction: column;
    &__seperator {
        position: relative;
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
    }

    &__label {
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    &__data {
        font-weight: 500;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: .25rem;
    }

}

.input-field {
    display: flex;
    margin-bottom: .25rem;
    padding: .25rem .75em;
    min-height: 1.5rem; //on mobile an empty field causes collapse
    border-radius: $br-sm;
    border: $border-width solid $color-border;
    background: $color-white;
    color: $color-border;
    width: calc(100% - .25rem);
    background: $color-white;
    align-items: center;
}