.item-card {
  font-family: Nunito, sans-serif;
  font-size: 1rem;
}
.item-card__container {
  position: relative;
  width: 14em;
  margin: 1em;
  border-radius: 1rem;
  padding: 0.75em;
  display: flex;
  flex-direction: column;
  background: #eee;
  list-style: none;
}
.item-card__section {
  display: flex;
  color: hsl(0deg, 0%, 13%);
}
.item-card__section--left {
  margin-right: auto;
}
.item-card__section--right {
  margin-left: auto;
}
.item-card__section--name {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.item-card__tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding-top: 0.5rem;
}
.item-card__tags__label {
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: hsl(0deg, 0%, 13%);
  display: flex;
  justify-content: center;
  width: 100%;
}
.item-card__tag {
  padding: 0.25rem 0.75rem;
  margin: 0.5rem 0.25rem;
  background-color: hsl(120deg, 40%, 70%);
  border-radius: 0.75rem;
  border: 1px solid hsl(0deg, 0%, 13%);
}
.item-card__buttons {
  width: 100%;
  margin-top: 1em;
  padding: 0.25em 0;
  /* May need to change display options down the road */
  display: flex;
  justify-content: space-around;
}
.item-card__delete {
  background-color: hsl(0deg, 40%, 70%);
  border-radius: 50%;
  border: 2px solid hsl(0deg, 0%, 13%);
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.-overlap {
  position: absolute;
  right: 0.75rem;
}

.-left {
  margin-left: 0;
  margin-right: auto;
}

.-right {
  margin-right: 0;
  margin-left: auto;
}

.button {
  font-family: Nunito, sans-serif;
  line-height: 1.25rem;
  min-width: 5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.75rem;
  border: 1px solid hsl(0deg, 0%, 13%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: hsl(0deg, 0%, 13%);
  /* for components that stand apart */
}
.button:focus, .button:active {
  filter: brightness(90%);
}
.button__add {
  background: hsl(120deg, 40%, 70%);
  min-width: 9rem;
}
.button__edit {
  background: hsl(30deg, 40%, 70%);
}
.button__delete {
  background: hsl(0deg, 40%, 70%);
}
.button__side {
  content: "  ";
  background: red;
  width: 1rem;
  height: 1rem;
}
.button.-submit, .button__logout {
  border-radius: 0.75rem;
  border: 2px solid hsl(0deg, 0%, 13%);
  background: hsl(0deg, 0%, 100%);
}
.button--solo {
  border: 2px solid hsl(0deg, 0%, 13%);
}

.category-card {
  position: relative;
  width: 18rem;
  margin: 4rem 1rem;
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  border: 2px solid hsl(0deg, 0%, 13%);
}
.category-card__header {
  position: absolute;
  top: -3rem;
  width: 20rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: 2px solid hsl(0deg, 0%, 13%);
  background: hsl(240deg, 40%, 70%);
}
.category-card__title {
  padding-top: 1rem;
  font-size: 1.75rem;
  text-shadow: 1px 1px 1px hsl(0deg, 0%, 13%), 1px -1px 1px hsl(0deg, 0%, 13%), -1px 1px 1px hsl(0deg, 0%, 13%), -1px -1px 1px hsl(0deg, 0%, 13%);
}
.category-card__buttons {
  width: 100%;
  margin: 1rem 0;
  padding: 0.25em 0;
  /* May need to change display options down the road */
  display: flex;
  justify-content: space-evenly;
}
.category-card__body {
  width: 100%;
  margin-top: 5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.form-container {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-category {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: 2px solid hsl(0deg, 0%, 13%);
  padding: 0.5rem;
  background: hsl(240deg, 40%, 70%);
}
.add-category__input-label {
  font-size: 0.9rem;
  color: hsl(0deg, 0%, 100%);
  margin-bottom: 0.5rem;
}
.add-category__input {
  display: flex;
  padding: 0.25rem 0.75em;
  border-radius: 0.5rem;
  border: 1px solid hsl(0deg, 0%, 13%);
  background: hsl(0deg, 0%, 100%);
  color: hsl(0deg, 0%, 13%);
  width: 100%;
  background: hsl(0deg, 0%, 100%);
  align-items: center;
  margin-bottom: 0.5rem;
}

.edit-category {
  position: absolute;
  width: 20rem;
  height: calc(100% + 4px);
  margin: 0 50% 0 50%;
  padding: calc(1rem - 2px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1rem;
  border: 2px solid hsl(0deg, 0%, 13%);
  background: hsl(240deg, 40%, 70%);
}
.edit-category__new-category {
  width: calc(100% + 4px);
  padding: 0.25rem 0.75rem;
  font-size: 1.5rem;
  background: hsl(0deg, 0%, 100%);
  border-radius: 0.75rem;
  border: 2px solid hsl(0deg, 0%, 13%);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: hsl(0deg, 0%, 13%);
}
.edit-category.display-false {
  visibility: hidden;
  z-index: -1;
}

.item-form {
  display: flex;
  flex-direction: column;
}
.item-form__input {
  color: hsl(0deg, 0%, 13%);
  background: hsl(0deg, 0%, 100%);
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid hsl(0deg, 0%, 13%);
}

.form__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cancel__button {
  position: absolute;
  top: 0.25rem;
  right: 1rem;
  z-index: 100;
}

.add-item, .add-item.button--solo {
  width: 9rem;
  z-index: 6;
  width: -moz-max-content;
  width: max-content;
}

.item-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 2;
  border-radius: 1rem;
  background: #cce;
}

.item-form__name-label {
  padding-left: 0.1rem;
  width: 12rem;
}

.item-name {
  width: 12rem;
}

.item-form__exp-label {
  font-size: 1rem;
  padding-left: 0.1rem;
  width: 12rem;
}

.item-form__label {
  margin: 0.25rem 0;
  margin-right: auto;
}
.item-form__input {
  width: 12rem;
  margin-bottom: 0.5rem;
}

.item-exp {
  width: 12rem;
}
.item-exp--off {
  display: none;
}

.item-form__expiration-toggle {
  width: 12rem;
  background: hsl(0deg, 40%, 70%);
}
.item-form__expiration-toggle--off {
  opacity: 1;
  bottom: 3.25rem;
}

.submit-item {
  border: 2px solid hsl(0deg, 0%, 13%);
}

.edit-item {
  position: absolute;
  top: -2px;
  left: calc(-50% - 2px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  margin-left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1rem;
  border: 2px solid hsl(0deg, 0%, 13%);
  background: hsl(240deg, 40%, 70%);
}
.edit-item__input-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem;
  width: 100%;
}
.edit-item__new-name, .edit-item__new-exp, .edit-item__new-quant {
  display: flex;
  padding: 0.25rem 0.75em;
  border-radius: 0.5rem;
  border: 1px solid hsl(0deg, 0%, 13%);
  background: hsl(0deg, 0%, 100%);
  color: hsl(0deg, 0%, 13%);
  width: calc(50% - 0.35rem);
  background: hsl(0deg, 0%, 100%);
  align-items: center;
}
.edit-item__new-name {
  margin-right: 0.35rem;
}
.edit-item__new-quant {
  margin-left: 0.35rem;
}
.edit-item__new-exp {
  margin-top: 0.7rem;
  min-width: 100%;
}
.edit-item.display-false {
  visibility: hidden;
  z-index: -1;
}

.login-form, .register-form {
  position: relative;
  margin: 0;
  padding: 0;
  height: 2.5rem;
  width: 5rem;
}
@media screen and (min-width: 1400px) {
  .login-form.-left, .register-form.-left {
    animation: to-right 1000ms ease-in-out forwards;
  }
}
@media screen and (min-width: 1400px) {
  .login-form.-right, .register-form.-right {
    animation: to-left 1000ms ease-in-out forwards;
  }
}
.login-form__input, .register-form__input {
  position: absolute;
  bottom: 0rem;
  font-size: 1rem;
  width: 5rem;
  padding: 0.5rem;
  border-radius: 0.75rem;
  border: 2px solid hsl(0deg, 0%, 13%);
  background: hsl(0deg, 0%, 100%);
}
.login-form__input.-submit, .register-form__input.-submit {
  z-index: 99;
  display: flex;
  justify-content: center;
}
.login-form__input.-submit.toggle-input, .register-form__input.-submit.toggle-input {
  animation: slide-input__1 1000ms ease-in-out forwards;
}
.login-form__input.-email, .register-form__input.-email {
  z-index: 97;
}
.login-form__input.-email.toggle-input, .register-form__input.-email.toggle-input {
  animation: slide-input__2 1000ms ease-in-out forwards;
}
.login-form__input.-password, .register-form__input.-password {
  z-index: 98;
}
.login-form__input.-password.toggle-input, .register-form__input.-password.toggle-input {
  animation: slide-input__3 1000ms ease-in-out forwards;
}
.login-form__input.-error, .register-form__input.-error {
  font-family: Georgia, serif;
  display: flex;
  left: -3.5rem;
  width: 12rem;
  height: 2.5rem;
  color: hsl(0deg, 90%, 50%);
  justify-content: center;
  align-content: center;
}

@keyframes to-right {
  0% {
    left: 0;
  }
  100% {
    left: 8.5rem;
  }
}
@keyframes to-left {
  0% {
    right: 0;
  }
  100% {
    right: 8.5rem;
  }
}
@keyframes slide-input__1 {
  100% {
    transform: translateX(8.5rem);
  }
}
@keyframes slide-input__2 {
  0% {
    width: 5;
  }
  100% {
    width: 8rem;
    transform: translateX(-8.5rem);
  }
}
@keyframes slide-input__3 {
  0% {
    width: 5;
  }
  100% {
    width: 8rem;
    transform: translateX(0);
  }
}
.category-list {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding-top: 4rem;
  padding-bottom: 3rem;
  display: flex;
  /*flex-direction: column;*/
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  color: #fff;
  background: #fff;
}

.guest-banner {
  padding: 1.5rem;
  width: 100%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: hsl(30deg, 40%, 70%);
  border-radius: 1rem;
  border: 2px solid hsl(0deg, 0%, 13%);
}
.guest-banner__message {
  font-size: 1.1rem;
  margin-right: auto;
  line-height: 2rem;
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 1400px) {
  .guest-banner__message {
    padding: 0;
  }
}
@media screen and (min-width: 550px) {
  .guest-banner {
    width: 80%;
  }
}
@media screen and (min-width: 1400px) {
  .guest-banner {
    flex-direction: row;
  }
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 9rem;
  width: 80px;
}
@media screen and (min-width: 1000px) {
  .user-info {
    margin-right: 2rem;
    margin-left: -1.25rem;
  }
}

.filter {
  display: flex;
  margin: 1rem;
  padding: 1rem;
  max-width: 95vw;
  flex-direction: column;
  background-color: hsl(240deg, 40%, 70%);
  border-radius: 2rem;
  border: 2px solid hsl(0deg, 0%, 13%);
}
.filter__filter {
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter__filter__input {
  margin-left: 0.5rem;
  padding: 0.25rem 0.75rem;
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 0.5rem;
  border: 1px solid hsl(0deg, 0%, 13%);
}
.filter__tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.filter__tag-container__tag {
  margin: 0 0.5rem;
}

.list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.error {
  border-radius: 0.75rem;
  border: 1px solid hsl(0deg, 0%, 13%);
  padding: 0.25rem 0.75rem;
}

.quantity {
  border-top: 1px dotted hsl(0deg, 0%, 13%);
  border-bottom: 1px dotted hsl(0deg, 0%, 13%);
  height: 1.5rem;
}
.quantity__seperator {
  width: 100%;
  height: 1.5rem;
  margin: 0;
  padding: 0rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quantity__label {
  font-weight: 500;
  display: flex;
  align-items: center;
}
.quantity__data {
  font-weight: 500;
}
.quantity__button {
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expiration {
  border-top: 1px dotted hsl(0deg, 0%, 13%);
  border-bottom: 1px dotted hsl(0deg, 0%, 13%);
  display: flex;
  flex-direction: column;
}
.expiration__seperator {
  position: relative;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}
.expiration__label {
  font-weight: 500;
  display: flex;
  align-items: center;
}
.expiration__data {
  font-weight: 500;
}
.expiration__button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.expiration__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.25rem;
}

.input-field {
  display: flex;
  margin-bottom: 0.25rem;
  padding: 0.25rem 0.75em;
  min-height: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid hsl(0deg, 0%, 13%);
  background: hsl(0deg, 0%, 100%);
  color: hsl(0deg, 0%, 13%);
  width: calc(100% - 0.25rem);
  background: hsl(0deg, 0%, 100%);
  align-items: center;
}

.card-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 550px) {
  .card-list {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media screen and (min-width: 800px) {
  .card-list {
    padding: 0;
  }
}
@media screen and (min-width: 1000px) {
  .card-list {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media screen and (min-width: 1400px) {
  .card-list {
    padding-left: 15%;
    padding-right: 15%;
  }
}

/*** The new CSS Reset - version 1.2.0 (last updated 23.7.2021) ***/
/* Remove all the styles of the "User-Agent-Stylesheets", except for the 'display' property */
*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  Remove list styles (bullets/numbers)
  in case you use it with normalize.css
*/
ol, ul {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
}

/* Removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

.body {
  min-height: 80vh;
  line-height: 1.25em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.header__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: hsl(240deg, 40%, 70%);
  border-bottom: 4px solid hsl(0deg, 0%, 13%);
}
@media screen and (min-width: 1000px) {
  .header__container {
    flex-direction: row;
    justify-content: space-between;
  }
  .header__container .logo {
    margin: 2rem;
    margin-right: 0;
  }
}

.logo {
  width: 4rem;
  margin: 2rem auto;
  aspect-ratio: 1;
  border-radius: 1rem;
  border: 2px solid hsl(0deg, 0%, 13%);
}

.header-title {
  font-size: 3rem;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 1px hsl(0deg, 0%, 13%), 1px -1px 1px hsl(0deg, 0%, 13%), -1px 1px 1px hsl(0deg, 0%, 13%), -1px -1px 1px hsl(0deg, 0%, 13%);
}

.link-container {
  margin: 1rem 2rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.link-container--guest {
  padding: 3.5rem;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8rem;
  color: hsl(0deg, 0%, 13%);
  background: hsl(240deg, 40%, 70%);
  border-top: 4px solid hsl(0deg, 0%, 13%);
}

.welcome {
  width: 100%;
  padding: 1rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: flex-start;
  color: hsl(0deg, 0%, 13%);
}
.welcome__section {
  position: relative;
  top: 3rem;
  margin: 2rem;
  padding: 2rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 2rem;
  border: 2px solid hsl(0deg, 0%, 13%);
}
.welcome__title {
  position: absolute;
  top: -3rem;
  left: -2rem;
  width: calc(100% + 4rem);
  padding: 1rem;
  text-align: center;
  font-size: 3rem;
  line-height: initial;
  border-radius: 2rem;
  border: 2px solid hsl(0deg, 0%, 13%);
  background: hsl(240deg, 40%, 70%);
}
.welcome__header {
  color: hsl(240deg, 40%, 70%);
  padding: 0.5rem 0;
  font-size: 2rem;
  line-height: initial;
}
.welcome__image {
  width: 200px;
  height: 200px;
  border-radius: 1rem;
  margin: 1rem;
}
.welcome__section > p {
  line-height: 1.5rem;
  align-self: flex-start;
}
.welcome__aside {
  padding-top: 2rem;
  width: 100%;
}
.welcome__action {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem 0.75rem;
  border-radius: 0.75rem;
  border: 2px solid hsl(0deg, 0%, 13%);
  background: hsl(120deg, 40%, 70%);
}
.welcome .display--false {
  display: none;
}/*# sourceMappingURL=styles.css.map */