@use '../abstracts' as *;

$form-field-width: 12rem;


.item-form {
    display: flex;
    flex-direction: column;

    &__input {
        color: $color-border;
        background: $color-white;
        padding: .25rem .75rem;
        border-radius: $br-sm;
        border: $border-width solid $color-border;
    }
}


.form__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.cancel__button {
    position: absolute;
    top: .25rem;
    right: 1rem;
    z-index: 100;
}

.add-item, .add-item.button--solo {
    width: 9rem;
    z-index: 6;
    width: max-content;
}

.item-form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    z-index: 2;
    border-radius: $br-lg;
    background: #cce;
}

.item-form__name-label {
    padding-left: .1rem;
    width: $form-field-width;

}

.item-name {
    width: $form-field-width;
}

.item-form__exp-label {
    font-size: 1rem;
    padding-left: .1rem;
    width: $form-field-width;
}

.item-form {

    &__label {        
        margin: .25rem 0;
        margin-right: auto;
    }

    &__input {
        width: $form-field-width;
        margin-bottom: .5rem;
    }
}


.item-exp {
    
    width: $form-field-width;

    &--off {
        display: none;
    }
}

.item-form__expiration-toggle {
    width: $form-field-width;
    background: $color-delete;
    
    &--off {
        opacity: 1;
        bottom: 3.25rem;
    }
}

.submit-item {
    border: $border-emphasis solid $color-border;
}