@use '../abstracts/' as *;
@use 'sass:map';

.card-list {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: map.get($breakpoints, "mini"))  {
        padding-left: 10%;
        padding-right: 10%;
    }

    @media screen and (min-width: map.get($breakpoints, "small")) {
        padding: 0;
    }

    @media screen and (min-width: map.get($breakpoints, "medium")) {
        padding-left: 10%;
        padding-right: 10%;
    }

    @media screen and (min-width: map.get($breakpoints, "large")) {
        padding-left: 15%;
        padding-right: 15%;
    }
}

