@use '../abstracts' as *;

.edit-item {
        position: absolute;
        top: -2px;
        left: calc(-50% - 2px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        margin-left: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: $br-lg;
        border: $border-emphasis solid $color-border;
        background: $color-primary;

        &__input-container {
            display: flex;
            flex-wrap: wrap;
            padding: .75rem;
            width: 100%;
            // keeps edit button from moving when toggled
            //margin-bottom: .5rem;
        }

        &__new-name, &__new-exp, &__new-quant {
            display: flex;
            padding: .25rem .75em;
            border-radius: $br-sm;
            border: $border-width solid $color-border;
            background: $color-white;
            color: $color-border;
            width: calc(50% - .35rem);
            background: $color-white;
            align-items: center;
        }

        &__new-name {
            margin-right: .35rem;
        }

        &__new-quant {
            margin-left: .35rem;
        }

        &__new-exp {
            margin-top: .7rem;
            min-width: 100%;
        }

        &.display-false {
            visibility: hidden;
            z-index: -1;
        }
    }