@use '../abstracts/' as *;

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8rem;
    color: $color-border;
    background: $color-primary;
    border-top: 4px solid $color-border;
}