@use '../abstracts/' as *;

.list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.error {
    border-radius: $br-md;
    border: $border-width solid $color-border;
    padding: .25rem .75rem;
}