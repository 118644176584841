@use '../abstracts' as *;
@use 'sass:map';

.category-list {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0;
    padding-top: 4rem;
    padding-bottom: 3rem;
    display: flex;
    /*flex-direction: column;*/
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    color: #fff;
    background: #fff;
}

.guest-banner {
    padding: 1.5rem;
    width: 100%;
    //60% for wide screen
    margin: 2rem auto;
    display: flex;
    flex-direction: column; // remove for wide screen
    justify-content: space-between;
    align-items: center;
    background: $color-edit;
    border-radius: $br-lg;
    border: $border-emphasis solid $color-border;

    &__message {
        font-size: 1.1rem;
        margin-right: auto;
        line-height: 2rem;
        padding-bottom: 1.5rem;

        @media screen and (min-width: map.get($breakpoints, "large")) {
            padding: 0;
        }
    }

    @media screen and (min-width: map.get($breakpoints, "mini")) {
        width: 80%;
    }
    @media screen and (min-width: map.get($breakpoints, "large")){
        flex-direction: row;
    }
}