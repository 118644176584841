@use '../abstracts/' as *;
@use 'sass:map';


.header__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $color-primary;
    border-bottom: $border-break solid $color-border;

    @media screen and (min-width: map.get($breakpoints, "medium")) {
        flex-direction: row;
        justify-content: space-between;
        .logo {
            margin: 2rem;
            margin-right: 0;
        }
    }
}

.logo {
    width: 4rem;
    margin: 2rem auto;
    aspect-ratio: 1;
    border-radius: 1rem;
    border: $border-emphasis solid $color-border;
}

.header-title {
    font-size: 3rem;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: .5rem;

    text-shadow:
    1px  1px 1px $color-border,
    1px -1px 1px $color-border,
   -1px  1px 1px $color-border,
   -1px -1px 1px $color-border;
}

.link-container {
    margin: 1rem 2rem;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--guest {
        padding: 3.5rem;
    }
}