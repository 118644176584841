@use '../abstracts/' as *;

.welcome {
    width: 100%;
    padding: 1rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: flex-start;
    color: $color-border;

    &__section {
        position: relative;
        top: 3rem;
        margin: 2rem;
        padding: 2rem;
        padding-top: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-radius: $br-xl;
        border: $border-emphasis solid $color-border;
    }

    &__title {
        position: absolute;
        top: -3rem;
        left: -2rem; //counters the padding from parent
        width: calc(100% + 4rem);
        padding: 1rem;
        text-align: center;
        font-size: 3rem;
        line-height: initial;
        border-radius: $br-xl;
        border: $border-emphasis solid $color-border;
        background: $color-primary;
    }

    &__header {
        color: $color-primary;
        padding: .5rem 0;
        font-size: 2rem;
        line-height: initial;
    }

    &__image {
        width: 200px;
        height: 200px;
        border-radius: 1rem;
        margin: 1rem;
    }

    &__section > p {
        line-height: 1.5rem;
        align-self: flex-start;
    }

    &__aside {
        padding-top: 2rem;
        width: 100%;
    }

    &__action {
        position: relative;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: .5rem .75rem;
        border-radius: $br-md;
        border: $border-emphasis solid $color-border;
        background: $color-add;
    }

    .display--false {
        display: none;
    }
}