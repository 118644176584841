@use '../abstracts/' as *;

.quantity {
    border-top: 1px dotted $color-border;
    border-bottom: 1px dotted $color-border;
    height: 1.5rem;
    &__seperator {
        width: 100%;
        height: 1.5rem;
        margin: 0;
        padding: 0rem .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__label {
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    &__data {
        font-weight: 500;
    }

    &__button {
        width: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

