@use '../abstracts' as *;

.form-container {
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-category {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: $br-lg;
    border: $border-emphasis solid $color-border;
    padding: .5rem;
    background: $color-primary;

    &__input-label {
        font-size: .9rem;
        color: $color-white;
        margin-bottom: .5rem;
    }

    &__input {
            display: flex;
            padding: .25rem .75em;
            border-radius: $br-sm;
            border: $border-width solid $color-border;
            background: $color-white;
            color: $color-border;
            width: 100%;
            background: $color-white;
            align-items: center;
            margin-bottom: .5rem;
    }
}