@use '../abstracts/' as *;
@use 'sass:map';

.user-info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 9rem;
    width: 80px; // constant width stops popping

    @media screen and (min-width: map.get($breakpoints, "medium")) {
        margin-right: 2rem;
        margin-left: -1.25rem; // The menu is wider than the logo on the opposite side. This corrects for it
    }
}