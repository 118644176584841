@use '../abstracts' as *;

.category-card {
    position: relative;
    width: 18rem;
    margin: 4rem 1rem;
    display: flex;
    justify-content: center;
    border-radius: $br-xl;
    border: $border-emphasis solid $color-border;

    &__header {
        position: absolute;
        top: -3rem;
        width: 20rem;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: $br-lg;
        border: $border-emphasis solid $color-border;
        background: $color-primary;
    }

    &__title {
        padding-top: 1rem;
        font-size: 1.75rem;
        text-shadow:
            1px  1px 1px $color-border,
            1px -1px 1px $color-border,
            -1px  1px 1px $color-border,
            -1px -1px 1px $color-border;
    }

    &__buttons {
        width: 100%;
        margin: 1rem 0;
        padding: .25em 0;
        /* May need to change display options down the road */
        display: flex;
        justify-content: space-evenly;
    }

    &__body {
        width: 100%;
        margin-top: 5rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
}