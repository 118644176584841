@use '../abstracts' as *;

$button-width: 5rem;
$button-wide: 9rem;
$button-padding: .5rem .75rem;
$button-radius: $br-md;


.button {
    font-family: map-get($font-stack, 'sans-serif');
    line-height: 1.25rem;
    min-width: $button-width;
    padding: $button-padding;
    border-radius: $button-radius;
    border: $border-width solid $color-border;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-border;

    &:focus, &:active {
        filter: brightness(90%);
    }

    
    &__add {
        background: $color-add;
        min-width: $button-wide;
    }

    &__edit {
        background: $color-edit;
    }

    &__delete {
        background: $color-delete;
    }

    &__side {
        content: "  ";
        background: red;
        width: 1rem;
        height: 1rem;
    }

    &.-submit, &__logout {
        border-radius: $br-md;
        border: $border-emphasis solid $color-border;
        background: $color-white;
    }

    /* for components that stand apart */
    &--solo {
        border: $border-emphasis solid $color-border;
    }

}