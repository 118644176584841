@use '../abstracts' as *;

.filter {
    display: flex;
    margin: 1rem;
    padding: 1rem;
    max-width: 95vw;
    flex-direction: column;
    background-color: $color-primary;
    border-radius: $br-xl;
    border: $border-emphasis solid $color-border;

    &__filter {
        width: 100%;
        margin-bottom: .5rem;
        display: flex;
        justify-content: center;
        align-items: center; 

        &__input {
            margin-left: .5rem;
            padding: .25rem .75rem;
            background-color: $color-white;
            border-radius: $br-sm;
            border: $border-width solid $color-border;
        }
    }

    &__tag-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        &__tag {
            margin: 0 .5rem;
        }
    }
}